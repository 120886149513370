import React, { useLayoutEffect } from 'react';

import { useFormikContext } from 'formik';
import Cleave from 'cleave.js';
// TODO: Check why the below workaround is necessary https://digitalunit-fs.atlassian.net/browse/CO-6147
// eslint-disable-next-line import/no-unresolved
import { CleaveOptions } from 'cleave.js/options';

import { getDisplayName } from '../../hoc-utilities';
import { ValidatedInput } from '../validated-input';

export type withCleaveProps = {
    name: string;
    cleaveOptions: CleaveOptions;
    handleChange?: ((e: React.ChangeEvent<{ rawValue: string }>) => void) | (() => void);
    withHtmlValidationWarning?: boolean;
};

export const withCleave = <TProps extends object>(
    WrappedComponent: React.ComponentType<TProps>,
): React.FC<TProps & withCleaveProps> => {
    const Wrapper: React.FC<TProps & withCleaveProps> = ({
        name,
        cleaveOptions,
        handleChange,
        ...props
    }: withCleaveProps) => {
        const { setFieldValue, initialValues } = useFormikContext();
        const stringifiedOptions = JSON.stringify(cleaveOptions);

        const inputSelector = `input[name="${name}"]`;
        const isInputElementMounted = document.querySelectorAll(inputSelector).length > 0;

        useLayoutEffect(() => {
            if (isInputElementMounted) {
                new Cleave(inputSelector, {
                    ...cleaveOptions,
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    initValue: (initialValues as any)[name],
                    onValueChanged: e => {
                        setFieldValue(`${name}`, e.target.value, true);
                        if (handleChange) {
                            handleChange(e);
                        }
                    },
                });
            }
            // TODO do proper cleanup
            return undefined;
        }, [setFieldValue, name, stringifiedOptions, isInputElementMounted]);

        return <WrappedComponent name={name} {...(props as TProps)} />;
    };

    Wrapper.displayName = `withCleave(${getDisplayName(WrappedComponent)})`;

    return Wrapper;
};

export const CleaveInput = withCleave(ValidatedInput);
