import React from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon/Icon';
/**
 * Bronson NotificationsGroup component.
 * Groups all related {@link Notification}.
 * @constructor
 */
export function NotificationsGroup({ children, testId, ...otherProps }) {
    const groupClassName = classNames('c-notifications-group', 'js-is-ready', // Used in Bronson CSS.
    'is-stacked').trim();
    return (React.createElement("section", { className: groupClassName, "data-testid": testId, ...otherProps }, children));
}
/**
 * Bronson Notification component (nested).
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-notification.html
 */
export function Notification({ buttons = null, children, className, closeLabel = 'Close Notification', context, onCloseClick, showCloseButton = true, status, text, title, visible, testId, ...otherProps }) {
    const containerClassName = classNames('c-notification__container', 'js-is-ready' // Used in Bronson CSS.
    ).trim();
    const divClassName = classNames('c-notification', {
        'c-notification--context': context,
        [`c-notification--${status}`]: status,
    }, className).trim();
    function renderButtons() {
        if (buttons) {
            return React.createElement("div", { className: "c-notification__buttons" }, buttons);
        }
        return null;
    }
    function renderCloseButton() {
        if (showCloseButton) {
            return (React.createElement("button", { className: "c-notification__close", title: closeLabel, type: "button", onClick: onCloseClick },
                React.createElement("span", { className: "u-visually-hidden" }, closeLabel)));
        }
        return null;
    }
    function renderIfStatus() {
        return status ? React.createElement(Icon, { className: "c-notification__status-icon", viaCss: true }) : null;
    }
    function renderIfTitle() {
        return title ? React.createElement("h3", { className: "c-notification__title" }, title) : null;
    }
    function renderText() {
        /**
         * If {@link text} is set, render it as content for `.c-notification__text`
         * and render {@link children} as sibling elements of `.c-notification__text`.
         * Else render out {@link children} as content for `.c-notification__text` only.
         * @DEPRECATED: Replace with `<div className="c-notification__text">{children}</div>` in v11.
         */
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "c-notification__text" }, text || children),
            text ? children : ''));
    }
    return visible ? (React.createElement("div", { className: containerClassName, "data-testid": testId },
        React.createElement("div", { className: divClassName, ...otherProps },
            React.createElement("div", { className: "c-notification__outer-wrap" },
                React.createElement("div", { className: "c-notification__inner-wrap" },
                    renderIfStatus(),
                    React.createElement("div", { className: "c-notification__content" },
                        renderIfTitle(),
                        renderText(),
                        renderButtons()),
                    renderCloseButton()))))) : null;
}
