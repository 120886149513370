import React from 'react';
import { KeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';

export const AuthenticationWrapper = (storyFn: () => React.ReactNode) => {
    const adapter: Keycloak.KeycloakAdapter = {
        accountManagement: (): Keycloak.KeycloakPromise<void, void> => {
            return Promise.resolve() as Keycloak.KeycloakPromise<void, void>;
        },
        redirectUri: (_options: { redirectUri: string }, _encodeHash: boolean): string => {
            return '';
        },
        register: (_options?: Keycloak.KeycloakLoginOptions): Keycloak.KeycloakPromise<void, void> => {
            return Promise.resolve() as Keycloak.KeycloakPromise<void, void>;
        },
        login: (_options?: Keycloak.KeycloakLoginOptions): Keycloak.KeycloakPromise<void, void> => {
            return Promise.resolve() as Keycloak.KeycloakPromise<void, void>;
        },
        logout: (_options?: Keycloak.KeycloakLogoutOptions): Keycloak.KeycloakPromise<void, void> => {
            return Promise.resolve() as Keycloak.KeycloakPromise<void, void>;
        },
    };

    const keycloak: Keycloak.KeycloakInstance = Keycloak({
        realm: 'realm',
        clientId: 'storybook',
    });

    return (
        <KeycloakProvider
            keycloak={{
                ...keycloak,
                authenticated: true,
                token: 'token',

                // @ts-expect-error: error is expected
                tokenParsed: 'tokenParsed',
            }}
            initConfig={{ adapter, onLoad: 'login-required', checkLoginIframe: false }}
        >
            {storyFn()}
        </KeycloakProvider>
    );
};
