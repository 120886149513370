import React, { Children, useCallback, useEffect, useMemo, useRef, } from 'react';
import classNames from 'classnames';
import { ComboboxProvider, comboboxReducerActions, useCombobox } from './ComboboxContext';
import { getActionFromKey, MenuActions } from './ComboboxCore';
import { Input } from '../Input/Input';
import uuidv4 from '../../utils/uuidv4';
/**
 * Check if one option is of type {@link ComboboxGroupOption}.
 * @param {ComboboxGroupOption | ComboboxOption} option
 * @returns {boolean}
 */
const isGroupedOption = (option) => !!option.label;
/**
 * Check if the passed options have the {@link ComboboxGroupOption} type signature.
 * @param {ComboboxOptionsType} options
 * @returns {boolean}
 */
const hasGroupOptions = (options) => options.every(isGroupedOption);
/**
 * Filter options if their value matches the {@link filterQuery}.
 * @param {ComboboxOptionsType} options
 * @param {string} filterQuery
 * @returns {ComboboxOptionsType}
 */
export const filterOptions = (options = [], filterQuery = '') => {
    if (hasGroupOptions(options)) {
        return options.map((groupOption) => ({
            label: groupOption.label,
            options: groupOption.options.filter((option) => option.value.toLowerCase().includes(filterQuery?.toLowerCase())),
        }));
    }
    else {
        return options.filter((option) => option.value.toLowerCase().includes(filterQuery?.toLowerCase()));
    }
};
/**
 * Flatten grouped options into a one-dimensional array to make it conform to the internal option type signature.
 * @param {ComboboxOptionsType} options
 * @returns {ComboboxOption[]}
 */
export const flattenGroupOptions = (options) => options.reduce((prev, curr) => {
    if (curr?.options) {
        return [...prev, ...curr.options];
    }
    else {
        prev.push(curr);
        return prev;
    }
}, []);
/**
 * Render the Combobox items depending on the signature of the {@link ComboboxOptionsType}.
 * When the options are of type {@link ComboboxGroupOption}, the items are put in a labeled optgroup.
 * @param {ComboboxOptionsType} options
 * @returns {React.ReactElement[]}
 */
function renderComboboxItems(options = []) {
    return options.map((item, i) => isGroupedOption(item) ? (React.createElement(ComboboxGroup, { label: item.label, key: `option-group-${i}` }, item.options.map(({ key, value, disabled }, j) => (React.createElement(Combobox.Item, { element: "li", key: `option-${i}-${j}`, optionKey: key, optionValue: value, disabled: disabled }, value))))) : (React.createElement(Combobox.Item, { key: `option-${i}`, optionKey: item.key, optionValue: item.value, disabled: item.disabled }, item.value)));
}
/**
 * The Combobox component.
 * @desc A Combobox is a select that enables the user to select one or more items from a list.
 * Compared to the native select the Combobox offers custom functionality and styling for options.
 *
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-combobox.html
 *
 * @constructor
 */
export function Combobox({ children, defaultState, open, options, ...internalComboboxArgs }) {
    if (options && children) {
        console.error('Combobox: You cannot use `options` and `children` together. Please use either the new `options` prop (recommended) pass in the `Combobox.Item` as children (deprecated).');
        return null;
    }
    /**
     * Modern way of rendering Combobox items via {@link options}.
     */
    if (options && !children) {
        const flatOptions = flattenGroupOptions(options);
        /**
         * Merge the internal default state with the passed {@link defaultState} and options.
         * @type {ComboboxState}
         */
        const initialState = {
            // Internal default state
            ...{
                options: [],
                value: [],
                currentIndex: null,
                filterQuery: '',
            },
            ...defaultState,
            ...{ options: flatOptions },
            ...{ defaultOptions: options },
            isOpen: open,
            isMulti: internalComboboxArgs.multiple,
            hasOptions: !!flatOptions?.length,
        };
        return (React.createElement(ComboboxProvider, { initialValue: initialState },
            React.createElement(ComboboxInternal, { ...internalComboboxArgs })));
    }
    else if (!options && children) {
        /**
         * Extract the current options from passed children.
         * @type {ComboboxOption[]}
         * @DEPRECATED Remove in v11.
         */
        const optionsFromChildren = useMemo(() => {
            const childrenArray = Children.toArray(children);
            return childrenArray.map(({ props: { optionValue, optionKey, disabled } }) => ({
                value: optionValue,
                key: optionKey,
                ...(disabled && { disabled }),
            }));
        }, [children]);
        /**
         * Merge the internal default state with the passed {@link defaultState} and options.
         * @type {ComboboxState}
         */
        const initialState = {
            // Internal default state
            ...{
                options: [],
                value: [],
                currentIndex: null,
                filterQuery: '',
            },
            ...defaultState,
            ...{ options: optionsFromChildren },
            ...{ defaultOptions: optionsFromChildren },
            isOpen: open,
            isMulti: internalComboboxArgs.multiple,
            hasOptions: !!optionsFromChildren?.length,
        };
        return (React.createElement(ComboboxProvider, { initialValue: initialState },
            React.createElement(ComboboxInternal, { ...internalComboboxArgs }, children)));
    }
    return null;
}
/**
 * The internal {@link ComboboxInternal} core implementation.
 *
 * @internal
 * @type {Partial<Combobox>} - The actual props that are passed to the component.
 * @return {JSX.Element} - The internal {@link ComboboxInternal} component.
 * @constructor
 */
function ComboboxInternal({ ariaLabelledBy, className, disabled, id, multiple = false, name, noOptionsMessage, onChange = () => { }, onKeyDown = () => { }, onListClose = () => { }, onListOpen = () => { }, placeholder, readOnly, searchable = false, searchInputLabel = 'Search list …', searchInputPlaceholder = 'Search list …', 
/**
 * The valueFormatter is opinionated according to the implementation in Bronson.
 * It can be customized as need be.
 */
valueFormatter = (values) => (values.length > 1 ? `${values.length} Options` : values[0]?.value), testId, ...otherProps }) {
    /**
     * Use the context provider via hook in the internal Combobox.
     */
    const [state, dispatch] = useCombobox();
    /**
     * Tracking the select ref.
     * @type {React.MutableRefObject<null>}
     */
    const selectRef = useRef(null);
    /**
     * Ref for the input.
     * @type {React.MutableRefObject<null>}
     */
    const inputRef = useRef(null);
    /**
     * Ref for the searchable input.
     * @type {React.MutableRefObject<null>}
     */
    const searchInputRef = useRef(null);
    /**
     * Memoize the {@link state?.touched} to avoid rerender.
     */
    const touched = useMemo(() => state?.touched, [state?.touched]);
    /**
     * Set the focus on the search input. Use a slight delay to prevent animation hiccups.
     * @type {(function(): void)|*}
     */
    const focusSearchInput = useCallback(() => {
        setTimeout(() => {
            searchInputRef?.current?.focus();
        }, 50);
    }, []);
    /**
     * Restore the Combobox focus state when closing/select-closing the menu.
     * Use a slight delay to prevent animation hiccups.
     * @type {(function(): void)|*}
     */
    const focusCombobox = useCallback(() => {
        setTimeout(() => {
            inputRef?.current?.focus();
        }, 50);
    }, []);
    /**
     * Call the callback only if the input was clicked or updated.
     * Do not include {@link touched} in the effect’s dependencies as we do not want to render on its changes.
     */
    useEffect(() => {
        if (touched) {
            onChange(state?.value ?? []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, state?.value]);
    /**
     * Handle clicks outside the {@link Combobox}.
     */
    useEffect(() => {
        const outsideClickHandler = (event) => {
            if (state?.isOpen && !selectRef?.current?.contains(event?.target)) {
                dispatch?.({ type: comboboxReducerActions.closeMenu });
                dispatch?.({ type: comboboxReducerActions.setFilterQuery, payload: '' });
                focusCombobox();
                onListClose();
            }
        };
        document.addEventListener('mousedown', outsideClickHandler, { capture: true });
        // Cleanup the hook.
        return () => document.removeEventListener('mousedown', outsideClickHandler, { capture: true });
    }, [dispatch, focusCombobox, onListClose, state?.isOpen]);
    /**
     * The component’s main element `classList`.
     * @type {string}
     */
    const classNameList = classNames('c-combobox__wrapper', className).trim();
    const valuePresentationClassNameList = classNames('c-combobox__value-presentation__text', {
        'has-placeholder': !state?.value?.length,
    }).trim();
    /**
     * The component’s main element sensible default attributes.
     * @type {{"aria-labelledby": {ariaLabelledBy}, "aria-controls": string, tabIndex: string|number, "aria-activedescendant": (string|string)}}
     */
    const comboboxAttributes = {
        'aria-activedescendant': state?.focusedOption?.key ? `${state?.focusedOption?.key}-option` : '',
        'aria-controls': `${id}-listbox`,
        'aria-labelledby': ariaLabelledBy,
        tabIndex: disabled ? undefined : 0,
    };
    /**
     * Handle clicks on the {@link Combobox}.
     * @type {(function(*): void)|*}
     */
    const onClickHandler = useCallback((event) => {
        /**
         * Only call the click handler when we really hit the {@link Combobox}.
         */
        if (event?.target === inputRef?.current) {
            /**
             * Toggle the menu/dropdown visibility.
             */
            dispatch?.({ type: comboboxReducerActions.toggleMenu });
            /**
             * If the menu is open, call the respective callback,
             * reset the filter query and manually reset the uncontrolled search input.
             * If it is closed set the focus to the search input if applicable.
             */
            if (state?.isOpen) {
                dispatch?.({ type: comboboxReducerActions.setFilterQuery, payload: '' });
                focusCombobox();
                onListClose();
            }
            else {
                focusSearchInput();
                onListOpen();
            }
        }
    }, [dispatch, focusCombobox, focusSearchInput, onListClose, onListOpen, state?.isOpen]);
    /**
     * Handle keyboard events on the {@link Combobox}.
     * @param {KeyboardEvent} event - The currently observed event.
     */
    // eslint-disable-next-line consistent-return
    const onKeydownHandler = (event) => {
        if (disabled || readOnly)
            return false;
        const action = getActionFromKey(event, state?.isOpen);
        if (typeof onKeyDown === 'function') {
            onKeyDown(event);
            if (event.defaultPrevented) {
                return false;
            }
        }
        /**
         * Handle keyboard interactions.
         *
         * Handle focus navigation of list items (arrow keys, home/end and pageUp/pageDown).
         */
        if (action === MenuActions.Next ||
            action === MenuActions.Next10 ||
            action === MenuActions.Last ||
            action === MenuActions.First ||
            action === MenuActions.Previous ||
            action === MenuActions.Previous10) {
            event.preventDefault();
            if (state?.isOpen) {
                dispatch?.({ type: comboboxReducerActions.focusOptionByAction, payload: { action } });
            }
            else {
                dispatch?.({ type: comboboxReducerActions.openMenu });
                onListOpen();
            }
            /**
             * Handle list item selection (space).
             */
        }
        else if (action === MenuActions.Select) {
            event.preventDefault();
            /**
             * Close menu in non-multiple mode after option selection.
             */
            if (!multiple || !state?.focusedOption) {
                dispatch?.({ type: comboboxReducerActions.closeMenu });
                dispatch?.({ type: comboboxReducerActions.setFilterQuery, payload: '' });
                onListClose();
                focusCombobox();
            }
            /**
             * Toggle the current focused option on select.
             */
            if (state?.focusedOption) {
                dispatch?.({ type: comboboxReducerActions.toggleOption, payload: state?.focusedOption });
            }
            /**
             * Handle list item selection and close (enter).
             */
        }
        else if (action === MenuActions.CloseSelect) {
            event.preventDefault();
            if (state?.focusedOption) {
                dispatch?.({ type: comboboxReducerActions.toggleOption, payload: state?.focusedOption });
            }
            dispatch?.({ type: comboboxReducerActions.closeMenu });
            dispatch?.({ type: comboboxReducerActions.setFilterQuery, payload: '' });
            focusCombobox();
            /**
             * Handle list close.
             */
        }
        else if (action === MenuActions.Close) {
            event.preventDefault();
            dispatch?.({ type: comboboxReducerActions.closeMenu });
            dispatch?.({ type: comboboxReducerActions.setFilterQuery, payload: '' });
            focusCombobox();
            /**
             * Handle list open. If the menu is not open, activate the respective option,
             * open the menu and manually focus the uncontrolled search input.
             */
        }
        else if (action === MenuActions.Open) {
            if (!state?.isOpen) {
                dispatch?.({ type: comboboxReducerActions.focusOptionByAction, payload: { action } });
            }
            dispatch?.({ type: comboboxReducerActions.openMenu });
            focusSearchInput();
            /**
             * Handle simple label select when not in {@link searchable} mode,
             * which is handled via {@link onSearchHandler}.
             */
        }
        else if (action === MenuActions.Type && !searchable) {
            event.preventDefault();
            event.stopPropagation();
            dispatch?.({ type: comboboxReducerActions.focusOptionByLabel, payload: { label: event.key.toLowerCase() } });
            if (!state?.isOpen) {
                dispatch?.({ type: comboboxReducerActions.openMenu });
                onListOpen();
            }
        }
    };
    /**
     * Handle search input.
     * @type {(function(*): void)|*}
     */
    const onSearchHandler = useCallback((event) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch?.({ type: comboboxReducerActions.setFilterQuery, payload: event.target.value });
    }, [dispatch]);
    /**
     * Reset the search when clicking the pseudo close button.
     * @param event
     */
    const onSearchResetHandler = (event) => {
        const eventTarget = event?.target;
        event.preventDefault();
        event.stopPropagation();
        if (!eventTarget?.value) {
            dispatch?.({ type: comboboxReducerActions.setFilterQuery, payload: '' });
        }
    };
    const listboxClassNames = classNames('c-combobox__listbox', { 'has-no-options': !state?.hasOptions });
    return (React.createElement("div", { className: classNameList, "data-name": name, ref: selectRef, "data-testid": testId, ...otherProps },
        React.createElement("input", { type: "hidden", name: "combobox-values" }),
        React.createElement("div", { "aria-haspopup": "listbox", "aria-expanded": state?.isOpen ?? false, "aria-owns": `${id}-listbox`, "aria-disabled": disabled, "aria-readonly": readOnly, className: "c-combobox", 
            /**
             * @TODO: We need to add this here to avoid warnings for missing `[aria-controls]` and `[aria-expanded]`
             *        because we do not include them as our Combobox does not support searchable functionality yet.
             */
            /* eslint-disable-next-line jsx-a11y/role-has-required-aria-props */
            role: "combobox", onClick: onClickHandler, onKeyDown: onKeydownHandler, onFocus: (event) => {
                if (event?.target === inputRef?.current && state?.focusedOption) {
                    dispatch?.({ type: comboboxReducerActions.blurOption });
                }
            }, ...comboboxAttributes, ref: inputRef },
            React.createElement("div", { className: "c-combobox__value-presentation" },
                React.createElement("span", { id: `${id}-values`, className: valuePresentationClassNameList }, valueFormatter(state?.value ?? []) || placeholder)),
            React.createElement("div", { className: "c-combobox__listbox-container" },
                searchable && (React.createElement("div", { className: "c-combobox__search-input" },
                    React.createElement(Input, { "aria-autocomplete": "list", "aria-controls": `${id}-listbox`, "aria-activedescendant": "", "aria-label": searchInputLabel, placeholder: searchInputPlaceholder, type: "search", onChange: onSearchHandler, onInput: onSearchResetHandler, value: state?.filterQuery, autoComplete: "off", ref: searchInputRef }))),
                React.createElement("div", { className: listboxClassNames, role: "listbox", "aria-multiselectable": multiple, id: `${id}-listbox`, tabIndex: 0, "data-combobox-no-options-message": noOptionsMessage }, searchable
                    ? renderComboboxItems(filterOptions(state?.defaultOptions, state?.filterQuery))
                    : renderComboboxItems(state?.defaultOptions))))));
}
/**
 * The ComboboxItem component.
 * @desc Used as direct child for the {@link Combobox}.
 * @constructor
 */
export function ComboboxItem({ className, children, disabled, element = 'div', onClick, optionKey, optionValue, testId, ...otherProps }) {
    const ref = useRef(null);
    const [state, dispatch] = useCombobox();
    const isActive = useMemo(() => state?.value?.find((value) => value?.key === optionKey), [state, optionKey]);
    const isCurrent = useMemo(() => state?.focusedOption?.key === optionKey, [state, optionKey]);
    const classNameList = classNames('c-combobox__item', { 'is-focused': isActive || isCurrent }, className).trim();
    /**
     * This assigns the `element` prop to a variable which is in PascalCase,
     * because custom component tags names must start with
     * an uppercase letter as lowercase tags are reserved for HTML.
     * @see https://reactjs.org/docs/jsx-in-depth.html#html-tags-vs.-react-components
     */
    // @ts-ignore
    const CustomElement = typeof element === 'string' ? `${element}` : element;
    /**
     * Handles clicks on the options. Toggle the option’s selected state?.
     * Calls {@link onClick} callback.
     */
    const onClickHandler = () => {
        dispatch?.({ type: comboboxReducerActions.toggleOption, payload: { key: optionKey, value: optionValue } });
        if (state?.isMulti) {
            dispatch?.({ type: comboboxReducerActions.focusOption, payload: { key: optionKey } });
        }
        else {
            dispatch?.({ type: comboboxReducerActions.closeMenu });
        }
        if (typeof onClick === 'function') {
            onClick(state?.value);
        }
    };
    /**
     * Hook to handle `scrollIntoView` of current focused options.
     */
    useEffect(() => {
        if (isCurrent) {
            ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        }
    }, [isCurrent]);
    return (React.createElement(CustomElement, { role: "option", ref: ref, id: `${optionKey}-option`, className: classNameList, "data-value": optionValue, "data-key": optionKey, "data-testid": testId, "aria-selected": !!isActive, "aria-disabled": disabled, onClick: onClickHandler, ...otherProps },
        React.createElement("div", { className: "c-combobox__item__text" }, children)));
}
ComboboxItem.displayName = 'Combobox.Item';
Combobox.Item = ComboboxItem;
export function ComboboxGroup({ label, children }) {
    const optGroupId = `combobox-optgroup-${uuidv4('')}`;
    return (React.createElement("ul", { className: "c-combobox__optgroup", role: "group", "aria-labelledby": optGroupId },
        React.createElement("li", { className: "c-combobox__optgroup-label", role: "presentation", id: optGroupId }, label),
        children));
}
ComboboxGroup.displayName = 'Combobox.Group';
Combobox.Group = ComboboxGroup;
