import { Layout, Button } from '@vwfs-bronson/bronson-react';
import React, { MouseEventHandler } from 'react';
import { InfoIcon } from '../info-icon';
import { Spinner } from '../spinner';

export type ContractActionItemType = {
    /**
     * The name of the icon from bronson guide
     */
    iconName: string;
    /**
     * The name of the URL of the page the link goes to
     */
    pageUrl?: string;
    /**
     * The name of contract action such as Amortization Table, Documents.
     */
    label: string;
    tooltip?: string | React.ReactNode;
    onClick?: MouseEventHandler;
    /**
     * The condition for showing a spinner instead of the Link
     */
    isLoading?: boolean;
    /**
     * To disable button (works only for buttons)
     */
    disabled?: boolean;
    /**
     * An optional test ID to be associated with the action item
     */
    testId?: string;

    iconReversed?: boolean;
};

export type ContractActionsProps = {
    contractActionItems: ContractActionItemType[];
    className?: string;
};

export const ContractActions: React.FC<ContractActionsProps> = ({ contractActionItems, className }) => {
    if (!contractActionItems) {
        return null;
    }

    return (
        <Layout className={className}>
            {contractActionItems.map((contractActionItem: ContractActionItemType, index: number) => (
                <Layout.Item key={index} className="u-1/3 u-1/1@s">
                    {contractActionItem.isLoading ? (
                        <Spinner small />
                    ) : contractActionItem.pageUrl ? (
                        <>
                            <Button
                                className={'c-btn c-btn--link c-btn--simple u-text-brand'}
                                icon={contractActionItem.iconName}
                                element="a"
                                href={contractActionItem.pageUrl}
                                iconReversed={contractActionItem.iconReversed}
                                onClick={contractActionItem.onClick}
                                testId={contractActionItem.testId}
                            >
                                {contractActionItem.label}
                            </Button>
                            {contractActionItem.tooltip && <InfoIcon text={contractActionItem.tooltip} />}
                        </>
                    ) : (
                        <>
                            <Button
                                className={'c-btn c-btn--link c-btn--simple u-text-brand'}
                                icon={contractActionItem.iconName}
                                iconReversed={false}
                                disabled={contractActionItem.disabled}
                                onClick={contractActionItem.onClick}
                                testId={contractActionItem.testId}
                            >
                                {contractActionItem.label}
                            </Button>
                            {contractActionItem.tooltip && <InfoIcon text={contractActionItem.tooltip} />}
                        </>
                    )}
                </Layout.Item>
            ))}
        </Layout>
    );
};
