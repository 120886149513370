import React from 'react';
import classNames from 'classnames';
/**
 * Bronson Logo component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-logo.html
 * @constructor
 */
export function Logo({ align, alt, ariaLabel, breakpoint, className, custom, disableLink, height, heightSmall, onClick, specificLogo, src, srcSmall, srcSet, title, testId, ...otherProps }) {
    /**
     * Conditionally add {@link height}, {@link heightSmall} and/or {@link align} as custom props.
     * @type {React.CSSProperties}
     */
    const logoCustomProps = {
        ...(height && { '--logo-image-height': height }),
        ...(heightSmall && { '--logo-image-height-small': heightSmall }),
        ...(align && { '--logo-image-align': align }),
    };
    const aClassNameList = classNames('c-logo', {
        'c-logo--has-image': src && !custom,
        'c-logo--custom': custom,
        [`c-logo--${specificLogo}`]: specificLogo,
    }, className).trim();
    /**
     * Merge the {@link logoCustomProps} into an existing style prop.
     */
    const logoStyles = { ...(otherProps?.style ?? {}), ...logoCustomProps };
    /**
     * Render the logo image(s).
     */
    function renderIfSrc() {
        if (src) {
            if (custom) {
                return (React.createElement(React.Fragment, null,
                    srcSmall && React.createElement("img", { src: srcSmall, alt: alt || '', className: "c-logo__image-small" }),
                    React.createElement("img", { src: src, alt: alt || '', className: "c-logo__image" })));
            }
            else {
                /**
                 * @DEPRECATED Remove statement in v10.
                 */
                return (React.createElement("picture", null,
                    breakpoint && srcSet && React.createElement("source", { srcSet: srcSet, media: `(max-width: ${breakpoint})` }),
                    React.createElement("img", { src: src, alt: alt || '', className: "c-logo__image" })));
            }
        }
        else {
            return null;
        }
    }
    /**
     * Conditionally render a `<span>` as Logo wrapper.
     * @type {string}
     */
    const LogoElement = disableLink ? 'span' : 'a';
    return (React.createElement(LogoElement, { className: aClassNameList, 
        /**
         * Display the `[title]` conditionally depending on
         * if {@link disableLink} is not set or {@link title} is set.
         */
        title: disableLink ? undefined : title, "data-testid": testId, "aria-label": src ? null : ariaLabel, role: disableLink ? undefined : 'link', onClick: disableLink ? undefined : onClick, ...otherProps, style: logoStyles }, renderIfSrc()));
}
