import React, { createContext, useEffect, useRef, useState } from 'react';

import { AnalyticsTracker } from './AnalyticsTracker';

export type AnalyticsContextType = {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    trackAction?: Function;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    trackPageView?: Function;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
    trackErrorPageView?: Function;
    userGroup?: string;
    privacyStatement?: boolean;
    isAuthenticated?: boolean;
    brand?: string;
    language?: string;
    // Used in dashboard tracker to determine if user has just registered
    registrationPath?: string;
    // Used in dashboard tracker to determine if user has just logged in
    loginPath?: string;
    productId?: string;
};

export const AnalyticsContext = createContext<AnalyticsContextType>({});

type AnalyticsProviderProps = {
    children?: React.ReactNode;
    userGroup?: string;
    privacyStatement?: boolean;
    isAuthenticated?: boolean;
    brand?: string;
    market: string;
    version: string;
    releaseDate: string;
    language: string;
    registrationPath?: string;
    loginPath?: string;
    productId?: string;
};

// Provides context for analytics hooks (wrap around MainPage.tsx)
export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
    children,
    userGroup,
    privacyStatement,
    isAuthenticated,
    brand,
    market,
    version,
    releaseDate,
    language,
    registrationPath,
    loginPath,
    productId,
}) => {
    const tracker = useRef<AnalyticsTracker>();
    const [isInitialized, setIsInitialized] = useState<boolean>(false);

    useEffect(() => {
        tracker.current = new AnalyticsTracker(market, version, releaseDate);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !isInitialized && setIsInitialized(true);
        return function cleanup() {
            tracker.current?.clearTimeOut();
        };
    }, [tracker]);

    if (!tracker.current) return null;
    const { trackAction, trackErrorPageView, trackPageView } = tracker.current;

    return (
        <AnalyticsContext.Provider
            value={{
                trackAction,
                trackPageView,
                trackErrorPageView,
                userGroup,
                privacyStatement,
                isAuthenticated,
                brand,
                language,
                registrationPath,
                loginPath,
                productId,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};
