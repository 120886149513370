import { MediaTeaser as MediaTeaserShared } from '@vwfs-bronson/bronson-react';
import React, { MouseEventHandler } from 'react';
import { ImageSrcset } from '@cp-shared-9/apis';

type FunctionType = () => void;

export type MediaTeaserProps = {
    title?: string;
    /**
     * Text that is shown above the title
     */
    pretitle?: string;
    imageUrl: string;
    buttonLabel?: string;
    clickHandler?: MouseEventHandler;
    hasLinkButton?: boolean;
    text?: string | React.ReactNode;
    reversed?: boolean;
    vertical?: boolean;
    className?: string;
    testId?: string;
    video?: React.ReactNode;
    buttonClassName?: string;
    fullLink?: boolean;
    fullLinkFooter?: React.ReactNode;
    buttonProps?: Record<string, string | boolean | React.ReactNode | FunctionType>;
};

export const MediaTeaser: React.FC<MediaTeaserProps> = ({
    title,
    pretitle,
    imageUrl,
    buttonLabel,
    clickHandler,
    hasLinkButton,
    text,
    reversed,
    vertical,
    className,
    testId = 'media-teaser',
    video,
    buttonClassName,
    fullLink = false,
    fullLinkFooter,
    buttonProps,
}) => {
    const imgProps: ImageSrcset = { src: imageUrl };
    return (
        <MediaTeaserShared
            title={title}
            pretitle={pretitle}
            imgProps={imgProps}
            buttonLabel={buttonLabel}
            onButtonClick={clickHandler}
            linkButton={hasLinkButton}
            reversed={reversed}
            vertical={vertical}
            className={className}
            testId={testId}
            video={video}
            buttonModifier={buttonClassName}
            fullLink={fullLink}
            fullLinkFooter={fullLinkFooter}
            buttonProps={buttonProps}
        >
            {text}
        </MediaTeaserShared>
    );
};
