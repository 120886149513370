/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useContext, createContext, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import useIntersectionObserver from '../../features/useIntersectionObserver';
import { ScrollObserverCore } from '../../features/ScrollObserver';
const TableContext = createContext({});
const TheadOrTbodyContext = createContext({});
const trackingRowStyle = {
    position: 'absolute',
    width: '100%',
    height: '0',
    top: 0,
    left: 0,
};
const trackingColumnStyle = {
    position: 'absolute',
    width: '0',
    height: '100%',
    left: 0,
    top: 0,
};
/**
 * Bronson Table component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-table--table.html
 * @constructor
 */
export const Table = ({ auto, bordered, children, className, colored, dataControls, fixedColumns, highlight, layoutFixed, narrow, noHover, noScroll, responsive, responsiveColumnHeader, responsiveRowHeader, stickyColumn, stickyHead, tableHeader, verticalAlign, wide, testId, ...otherProps }) => {
    /**
     * Throw a console warning when `responsive` prop is used.
     * @DEPRECATED Remove in v11.
     */
    if (responsive) {
        console.warn('Warning: The `responsive` prop is deprecated and will be removed from Bronson-React 11. Please use `responsiveColumnHeader` instead.');
    }
    const tableWrapperRef = useRef(null);
    /**
     * Implement the sticky table behavior by utilizing two tracking elements
     * that signals if the table has either an intersecting header row or first column.
     * This deviates from how Bronson implements this (by tracking individual cells).
     * It also does not implement calculating parent and grandparent node offsets.
     */
    const stickColumnRef = useRef(null);
    const stickRowRef = useRef(null);
    /**
     * The state is tracked for either direction individually.
     */
    const [hasStickyHead, setHasStickyHead] = useState(false);
    const [hasStickyColumn, setHasStickyColumn] = useState(false);
    const [hasStickyColumnOverflow, setHasStickyColumnOverflow] = useState(false);
    /**
     * Function to update the `--scroll-width` custom property
     * (and `.has-overflow-clip` class) on the document root.
     */
    function updateScrollWidth(tableWrapper, table) {
        if (!tableWrapper || !table) {
            return;
        }
        const tableWrapperWidth = tableWrapper.getBoundingClientRect().width;
        const tableWidth = table.getBoundingClientRect().width;
        /**
         * The `.has-scroll-overflow` class is used to fake-scroll the table via CSS positioning,
         * but only when it’s necessary (when table is wider than its wrapper).
         */
        setHasStickyColumnOverflow(tableWidth > tableWrapperWidth);
        // Remove `--scroll-width` custom property from document root to not affect the following assignment.
        document.documentElement.style.removeProperty('--scroll-width');
        // Remove `.has-overflow-clip` class from document root to not affect the following assignment.
        document.documentElement.classList.remove('has-overflow-clip');
        // Width of the scrollable area (including the over-scrolling tables).
        const { scrollWidth } = document.body;
        /**
         * Add {@link scrollWidth} as custom property to document root.
         * `--scroll-width` is used to set the width of the sticky body to avoid
         * too early or unnecessary horizontal page scrolling.
         */
        document.documentElement.style.setProperty('--scroll-width', `${scrollWidth}px`);
        /**
         * Add `.has-overflow-clip` class to the document root.
         * Together with CSS this is a workaround for a bug in Firefox,
         * where it calculates an incorrect total scroll width.
         */
        document.documentElement.classList.add('has-overflow-clip');
    }
    /**
     * Function to update transform values of root and tables based
     * on the scroll status. Negative `--scroll-offset` value will be used
     * to position tables in the opposite scrolling direction.
     */
    function updateScrollOffset() {
        document.documentElement.style.setProperty('--scroll-offset', `${window.scrollX}px`);
    }
    /**
     * Append 'minimum-scale=1' to the `[content]` attribute of `<meta name="viewport">` if it does not already exist.
     * This fixes a behavior in Chromium-based mobile browsers where `window.scrollX` is otherwise always `0`.
     * @see https://stackoverflow.com/a/59443204
     */
    function setViewportMinimumScale() {
        // Select the viewport meta tag from the document.
        const viewportMeta = document.querySelector('meta[name="viewport"]');
        // If the viewport meta tag exists.
        if (viewportMeta) {
            const viewportContent = viewportMeta?.getAttribute('content');
            // If the `[content]` attribute does not include 'minimum-scale'.
            if (!viewportContent?.includes('minimum-scale')) {
                viewportMeta.setAttribute('content', `${viewportContent}, minimum-scale=1`);
            }
        }
        else {
            // If the viewport meta tag does not exist, create it.
            const meta = document.createElement('meta');
            meta.name = 'viewport';
            meta.content = 'width=device-width, initial-scale=1, minimum-scale=1';
            document.head.appendChild(meta);
        }
    }
    if (stickyHead || stickyColumn) {
        useEffect(() => {
            let resizeObserver;
            setViewportMinimumScale();
            const table = tableWrapperRef?.current?.querySelector('table');
            if (table && tableWrapperRef?.current) {
                // Update scroll width when a sticky table changes dimensions.
                resizeObserver = new ResizeObserver(() => {
                    updateScrollWidth(tableWrapperRef?.current, table);
                });
                // Observe table element.
                resizeObserver.observe(table);
            }
            // Add custom props on page load.
            updateScrollOffset();
            return () => {
                if (table) {
                    resizeObserver.unobserve(table);
                }
            };
        }, []);
        /**
         * Track resizes on the window to adjust scroll width of `<html>` element.
         */
        useEffect(() => {
            // Update scroll width on resize.
            let resizeRAF;
            const resizeCallback = () => {
                cancelAnimationFrame(resizeRAF);
                resizeRAF = requestAnimationFrame(() => {
                    const table = tableWrapperRef?.current?.querySelector('table');
                    if (table && tableWrapperRef?.current) {
                        updateScrollWidth(tableWrapperRef?.current, table);
                    }
                });
            };
            window.addEventListener('resize', resizeCallback, { passive: true });
            return () => {
                window.removeEventListener('resize', resizeCallback);
            };
        }, []);
        /**
         * Update the scroll offset of the `<html>` element.
         */
        useEffect(() => {
            // Update scroll offset on scroll.
            let scrollRAF;
            const scrollCallback = () => {
                cancelAnimationFrame(scrollRAF);
                scrollRAF = requestAnimationFrame(updateScrollOffset);
            };
            window.addEventListener('scroll', scrollCallback, { passive: true });
            return () => {
                window.removeEventListener('scroll', scrollCallback);
            };
        }, []);
        /**
         * Track the header row tracking element.
         */
        useIntersectionObserver({
            ref: tableWrapperRef,
            target: stickRowRef,
            onIntersectionHandler: () => ([entry]) => setHasStickyHead(!entry?.isIntersecting),
            opts: {
                classes: ScrollObserverCore.classes,
                intersectionObserverOpts: { rootMargin: '0px', threshold: [0, 1] },
            },
        });
        /**
         * Track the column tracking element.
         */
        useIntersectionObserver({
            ref: tableWrapperRef,
            target: stickColumnRef,
            onIntersectionHandler: () => ([entry]) => {
                // Left position of the table wrapper.
                const tableWrapperLeft = tableWrapperRef?.current?.getBoundingClientRect().left ?? 0;
                const table = tableWrapperRef?.current?.querySelector('table');
                if (table && tableWrapperRef?.current) {
                    // Left position of the cell.
                    const cellLeftPos = entry.boundingClientRect.left;
                    // If the table wrapper or table have a left margin, we need to calculate with it.
                    const tableWrapperMarginLeft = parseFloat(window.getComputedStyle(tableWrapperRef?.current).marginLeft);
                    const tableMarginLeft = parseFloat(window.getComputedStyle(table).marginLeft);
                    const marginLeft = tableWrapperMarginLeft + tableMarginLeft;
                    /**
                     * Detect that the cell is sticky when its left boundary is less than `0px` from the left border of the viewport.
                     * Detect that the cell is sticky when the table wrapper (+ table margin) is further left than the cell.
                     * This is the case when the table does not stick to the left edge of the viewport, but to another container.
                     */
                    // eslint-disable-next-line no-negated-condition
                    const isSticky = tableWrapperLeft + marginLeft - cellLeftPos !== 0 ? true : cellLeftPos < 0;
                    setHasStickyColumn(isSticky);
                }
            },
            opts: {
                classes: ScrollObserverCore.classes,
                intersectionObserverOpts: { rootMargin: '0px', threshold: [0, 1] },
            },
        });
    }
    const classNameList = classNames('c-table-wrapper', {
        'c-table-wrapper--auto': auto,
        'c-table-wrapper--no-scroll': noScroll,
        'c-table--bordered': bordered,
        'c-table--colored': colored,
        'c-table--data-controls': dataControls,
        [`c-table--fixed-column-${fixedColumns}`]: fixedColumns,
        'c-table--highlight': highlight,
        'c-table--layout-fixed': layoutFixed,
        'c-table--narrow': narrow,
        'c-table--no-hover': noHover,
        'c-table--responsive': responsive /** @DEPRECATED Remove in v11. */,
        'c-table--responsive-column': responsiveColumnHeader,
        'c-table--responsive-row': responsiveRowHeader,
        'c-table--sticky-column': stickyColumn,
        'c-table--sticky-head': stickyHead,
        'c-table--wide': wide,
        'has-sticky-head': hasStickyHead,
        'has-scroll-overflow': hasStickyColumnOverflow,
        'has-sticky-column': hasStickyColumn,
    }, className).trim();
    return (React.createElement("div", { className: classNameList, ref: tableWrapperRef },
        React.createElement(TableContext.Provider, { value: { tableHeader, responsive, responsiveColumnHeader, responsiveRowHeader } },
            React.createElement("table", { role: "table", "data-vertical-align": verticalAlign, "data-testid": testId, ...otherProps }, children)),
        stickyColumn && React.createElement("div", { ref: stickColumnRef, style: trackingColumnStyle, "aria-hidden": true }),
        stickyHead && React.createElement("div", { ref: stickRowRef, style: trackingRowStyle, "aria-hidden": true })));
};
Table.Caption = Caption;
Table.Thead = Thead;
Table.Tbody = Tbody;
Table.Tfoot = Tfoot;
Table.Tr = Tr;
Table.Th = Th;
Table.Td = Td;
Table.Subheading = Subheading;
/**
 * Table Caption component.
 * @constructor
 */
function Caption({ children }) {
    return React.createElement("caption", null, children);
}
/**
 * Table Head component.
 * @constructor
 */
function Thead({ children, verticalAlign, testId }) {
    return (React.createElement(TheadOrTbodyContext.Provider, { value: { type: 'head' } },
        React.createElement("thead", { role: "rowgroup", "data-vertical-align": verticalAlign, "data-testid": testId }, children)));
}
/**
 * Table Body component.
 * @constructor
 */
function Tbody({ children, verticalAlign, testId }) {
    const tableContext = useContext(TableContext);
    return (React.createElement(TheadOrTbodyContext.Provider, { value: { type: 'body' } },
        React.createElement("tbody", { role: "rowgroup", "data-vertical-align": verticalAlign, "data-testid": testId }, React.Children.map(children, (child, index) => 
        /**
         * If {@link responsiveRowHeader} is set and the current index
         * matches an existing value in {@link tableHeader}, then {@link tableRowIndex}
         * is also passed to the child elements, which is needed as an identifier
         * for the current {@link Table.Tr}.
         */
        tableContext?.responsiveRowHeader && tableContext?.tableHeader?.[index]
            ? React.cloneElement(child, { tableRowIndex: index })
            : child))));
}
/**
 * Table Footer component.
 * @constructor
 */
function Tfoot({ children, verticalAlign, testId }) {
    return (React.createElement("tfoot", { role: "rowgroup", "data-vertical-align": verticalAlign, "data-testid": testId }, children));
}
/**
 * Table Row component.
 * @constructor
 */
function Tr({ children, className, disabled, selected, status, tableRowIndex, verticalAlign, ...otherProps }) {
    const tableContext = useContext(TableContext);
    const headOrBodyContext = useContext(TheadOrTbodyContext);
    const classNameList = classNames({
        // Convenience props.
        'is-disabled': disabled,
        'is-selected': selected,
        [`is-${status}`]: status,
    }, className).trim();
    return (
    /**
     * Need to pass `[inert=""]` as React currently does not support HTMLElement.inert mapping.
     * @see https://github.com/facebook/react/pull/24730
     * @TODO: Remove once React supports direct `[inert]` usage.
     * @see BRON-11871
     */
    React.createElement("tr", { className: classNameList, role: "row", "aria-disabled": disabled ?? null, 
        // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
        inert: disabled ? '' : null, "data-vertical-align": verticalAlign, ...otherProps }, headOrBodyContext?.type === 'body' &&
        (tableContext?.responsive || tableContext?.responsiveColumnHeader || tableContext?.responsiveRowHeader)
        ? React.Children.map(children, (child, index) => {
            /**
             * Skip {@link Table.Th} and assume it is the first child.
             */
            if (index === 0) {
                return child;
            }
            /**
             * Handle {@link tableHeader} for {@link responsiveColumnHeader}.
             */
            const columnHeader = tableContext?.responsive || tableContext?.responsiveColumnHeader
                ? tableContext?.tableHeader?.[index - 1]
                : null;
            /**
             * Handle {@link tableHeader} for {@link responsiveRowHeader}.
             */
            const rowHeader = tableContext?.responsiveRowHeader ? tableContext?.tableHeader?.[tableRowIndex] : null;
            return React.cloneElement(child, {
                'data-columnheader': columnHeader,
                'data-rowheader': rowHeader,
            });
        })
        : children));
}
/**
 * Table Header component.
 * @constructor
 */
function Th({ children, className, disabled, status, verticalAlign, ...otherProps }) {
    const headOrBodyContext = useContext(TheadOrTbodyContext);
    const classNameList = classNames({
        // Convenience props.
        'is-disabled': disabled,
        [`is-${status}`]: status,
    }, className).trim();
    let scopeVal;
    let roleVal;
    if (headOrBodyContext?.type === 'head') {
        scopeVal = children ? 'col' : undefined;
        roleVal = 'columnheader';
    }
    else if (headOrBodyContext?.type === 'body') {
        scopeVal = children ? 'row' : undefined;
        roleVal = 'rowheader';
    }
    return (React.createElement("th", { className: classNameList, scope: scopeVal, role: roleVal, "aria-disabled": disabled, "data-vertical-align": verticalAlign, ...otherProps }, children));
}
/**
 * Table Data Cell component.
 * @constructor
 */
function Td({ children, className, disabled, status, verticalAlign, ...otherProps }) {
    const classNameList = classNames('c-table__cell', {
        // Convenience props.
        'is-disabled': disabled,
        [`is-${status}`]: status,
    }, className).trim();
    return (
    /**
     * Need to pass `[inert=""]` as React currently does not support HTMLElement.inert mapping.
     * @see https://github.com/facebook/react/pull/24730
     * @TODO: Remove once React supports direct `[inert]` usage.
     * @see BRON-11871
     */
    React.createElement("td", { className: classNameList, role: "cell", 
        // @ts-ignore @TODO: Remove once React supports inerts, @see BRON-11871.
        inert: disabled ? '' : null, "data-vertical-align": verticalAlign, ...otherProps }, children));
}
/**
 * Table Subheading component.
 * @constructor
 */
function Subheading({ children, className, headingLevel = 4, ...otherProps }) {
    const classNameList = classNames('c-table__subheading', className).trim();
    const CustomTagHeadingLevel = `h${headingLevel}`;
    /**
     * The {@link id} is required for reasons of accessibility and serves as an
     * indicator for the associated content of the {@link Subheading}.
     */
    const { id, ...trProps } = otherProps;
    /**
     * Apart from Bronson, we have set the maximum value of `[colspan]` to `1000`,
     * because according to the HTML specs, a higher value is invalid.
     * @see https://html.spec.whatwg.org/multipage/tables.html#attr-tdth-colspan
     */
    return (React.createElement("tr", { className: classNameList, role: "row", ...trProps },
        React.createElement("th", { scope: "rowgroup", id: id, colSpan: 1000, role: "rowheader" },
            React.createElement(CustomTagHeadingLevel, { className: "c-table__subheading__title" },
                React.createElement("span", { className: "c-table__subheading__title__text" }, children)))));
}
