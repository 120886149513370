import React, { FC } from 'react';
import { Accordion, AccordionItem } from '../accordion';
import { FaqCMS, FaqItemCMS, FaqItemGroupCMS } from '@cp-shared-9/apis';

type FaqAdditionalProps = {
    /**
     * Set this to true to open multiple faq items at one time
     */
    openOneAtTime?: boolean;
    /**
     * OnChange for FaqSection
     */
    onChange?: (items: string[]) => void;
};

type FaqSectionProps = FaqAdditionalProps & {
    /**
     * Array of Faq items which can contain html markup or grouped items
     */
    items: FaqItemCMS[];
};

export const Faq: FC<FaqCMS & FaqAdditionalProps> = ({
    header,
    descriptionText,
    items,
    itemGroups,
    openOneAtTime = true,
    onChange,
}) => {
    return (
        <section>
            <h2 className={'cp-faq-header'}>{header}</h2>
            <div
                dangerouslySetInnerHTML={{
                    __html: `<p className={'cp-faq-description'}>${descriptionText || ''}</p>`,
                }}
            />
            {!items && itemGroups ? (
                <Accordion onChange={onChange}>
                    {itemGroups &&
                        itemGroups.map((itemGroup: FaqItemGroupCMS) => (
                            <AccordionItem key={itemGroup.category} title={itemGroup.category}>
                                <FaqSectionView items={itemGroup.items} openOneAtTime={openOneAtTime} />
                            </AccordionItem>
                        ))}
                </Accordion>
            ) : (
                items && <FaqSectionView onChange={onChange} items={items} openOneAtTime={openOneAtTime} />
            )}
        </section>
    );
};

const FaqSectionView: FC<FaqSectionProps> = ({ items, openOneAtTime = true, onChange }) => {
    const itemsWithRenderedHtml = items.map(({ question, answer }) => ({
        title: question,
        content: <div dangerouslySetInnerHTML={{ __html: answer }} />,
    }));
    return (
        <Accordion openOneAtTime={openOneAtTime} onChange={onChange}>
            {itemsWithRenderedHtml.map(item => (
                <AccordionItem key={item.title} title={item.title}>
                    {item.content}
                </AccordionItem>
            ))}
        </Accordion>
    );
};
