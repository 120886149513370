import React from 'react';
import { AxiosResponse } from 'axios';
import { NotificationsCenterItem, useNotificationsCenter } from './useNotificationsCenter';
import { NotificationCenter } from '@vwfs-bronson/bronson-react';
import { NotificationNC } from '@cp-shared-9/apis';

const createItems = (
    notifications: NotificationsCenterItem[],
    setReadStatus: (notificationItem: NotificationsCenterItem, toggle?: boolean) => void,
): Map<string | number, React.ReactNode> => {
    const res = new Map<string, React.ReactNode>();
    if (!notifications.length) return res;

    notifications.forEach(notification => {
        const { id, read, date, onClick, onMarkReadUnread, ...props } = notification;
        res.set(
            id,
            <NotificationCenter.Item
                key={id}
                unread={!read}
                datetime={date}
                testId={`notification-item-${notification.id}`}
                onClick={(): void => {
                    setReadStatus(notification);
                    onClick();
                }}
                onMarkReadUnread={(): void => {
                    setReadStatus(notification, true);
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    onMarkReadUnread && onMarkReadUnread();
                }}
                {...props}
            >
                <p>{notification.content}</p>
            </NotificationCenter.Item>,
        );
    });

    return res;
};

const nativeItemsMapper = (notifications: NotificationNC[]): NotificationsCenterItem[] => {
    return notifications.map(notification => {
        return {
            id: notification.id,
            title: notification.title,
            content: notification.content,
            read: notification.read,
            date: notification.date,
            onClick: (): void => {
                // eslint-disable-next-line no-console
                console.warn('onClick or itemsMapper is not implemented');
            },
        };
    });
};

export type NotificationsCenterProps = {
    fetchFunction: () => Promise<AxiosResponse>;
    itemsMapper?: (notifications: NotificationNC[]) => NotificationsCenterItem[];
    intervalInMinutes: number;
    toggleLabel: string;
    panelHeading: string;
    closeButtonLabel?: string;
};

type NotificationData = {
    id: string | number;
    title: string;
    read: boolean;
};

export const NotificationsCenter: React.FC<NotificationsCenterProps> = ({
    fetchFunction,
    itemsMapper,
    intervalInMinutes,
    toggleLabel,
    panelHeading,
    closeButtonLabel = '',
}) => {
    const { notificationsCenter, update } = useNotificationsCenter(fetchFunction, intervalInMinutes);

    const setReadStatus = (notificationItem: NotificationsCenterItem, toggle = false): void => {
        const updatedNotificationsCenter = [...notificationsCenter];
        const updatedNotificationItem = updatedNotificationsCenter.find(item => item.id === notificationItem.id);
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        updatedNotificationItem && (updatedNotificationItem.read = toggle ? !updatedNotificationItem.read : true);
        update(updatedNotificationsCenter);
    };

    const mappedItems = itemsMapper ? itemsMapper(notificationsCenter) : nativeItemsMapper(notificationsCenter);
    const items = createItems(mappedItems, setReadStatus);

    return (
        <NotificationCenter
            closeButtonLabel={closeButtonLabel}
            toggleLabel={toggleLabel}
            panelHeading={panelHeading}
            testId={'notifications-center'}
            notifications={mappedItems}
        >
            {(data: NotificationData): React.ReactElement => items.get(data.id) as React.ReactElement}
        </NotificationCenter>
    );
};
