import { Accordion as AccordionShared } from '@vwfs-bronson/bronson-react';
import React from 'react';

export type AccordionProps = {
    /** A collection of {@link AccordionItem}. */
    children?: React.ReactElement | React.ReactElement[];
    /**
     * Can you have more than 1 item open at the same time?
     */
    openOneAtTime?: boolean;
    /**
     * If set to true, adds a background color to the panel
     */
    withBackground?: boolean;
    /** Optional callback when toggling the panel. */
    onChange?: (items: string[]) => void;
    /**
     * enables conditional rendering of hidden elements to improve performance in case of complex children, default is false
     */
    lazyRender?: boolean;
    className?: string;
    testId?: string;
    itemKeyProp?: string;
    headerSplit?: boolean;
};

export const Accordion: React.FC<AccordionProps> = ({
    className,
    openOneAtTime,
    testId,
    withBackground,
    itemKeyProp,
    onChange,
    lazyRender,
    headerSplit,
    children,
}) => {
    if (!children) {
        return null;
    }
    return (
        <AccordionShared
            className={className}
            multipleOpen={openOneAtTime}
            testId={testId}
            withBackground={withBackground}
            itemKeyProp={itemKeyProp}
            onChange={onChange}
            lazyRender={lazyRender}
            headerSplit={headerSplit}
        >
            {children}
        </AccordionShared>
    );
};

Accordion.displayName = 'Accordion';
