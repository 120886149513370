import { HeroTeaser as HeroTeaserShared } from '@vwfs-bronson/bronson-react';
import React, { MouseEventHandler } from 'react';
import { FocalPoint, ImageSrcset } from '@cp-shared-9/apis';

export type HeroImageProps = {
    className?: string;
    pretitle?: string;
    title: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    text?: string | React.ReactNode;
    imageUrl?: string;
    buttonText?: string;
    clickHandler?: MouseEventHandler;
    shallow?: boolean;
    inverted?: boolean;
    focalPoint?: FocalPoint;
    auto?: boolean;
    buttonIsHiddenOnLargeScreen?: boolean;
    testId?: string;
};

export const HeroImage: React.FC<HeroImageProps> = ({
    className,
    pretitle,
    title,
    subTitle,
    text,
    imageUrl,
    buttonText,
    clickHandler,
    shallow,
    inverted,
    focalPoint,
    auto,
    buttonIsHiddenOnLargeScreen = false,
    testId,
}) => {
    const imgProps: ImageSrcset = { src: imageUrl };
    return (
        <HeroTeaserShared
            className={className}
            pretitle={pretitle}
            title={title}
            sub={subTitle}
            imgProps={imgProps}
            buttonText={buttonText}
            onButtonClick={clickHandler}
            shallow={shallow}
            noImage={!imageUrl}
            inverted={!!inverted}
            topLeft={focalPoint === 'top-left'}
            topCenter={focalPoint === 'top-center'}
            topRight={focalPoint === 'top-right'}
            centerLeft={focalPoint === 'center-left'}
            centerRight={focalPoint === 'center-right'}
            bottomLeft={focalPoint === 'bottom-left'}
            bottomCenter={focalPoint === 'bottom-center'}
            bottomRight={focalPoint === 'bottom-right'}
            auto={auto}
            buttonIsHiddenOnLargeScreen={buttonIsHiddenOnLargeScreen}
            testId={testId}
        >
            {text}
        </HeroTeaserShared>
    );
};
