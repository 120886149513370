import React, { useState, useRef, useEffect, useContext, } from 'react';
import classNames from 'classnames';
import uuid from '../../utils/uuidv4';
import { Icon } from '../Icon/Icon';
/**
 * The SiteNavContext holds the open state of the menu
 * and callback functions to control the state.
 * @type {React.Context<{}>}
 */
const SiteNavContext = React.createContext({});
/**
 * Bronson SiteNav component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-site-nav.html
 * @constructor
 */
export const SiteNav = ({ children, className, closeMenuText, openMenuText, title = 'Show Navigation', testId, ...otherProps }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [open, setOpen] = useState(null);
    const contextValue = {
        /**
         * The value of open state.
         */
        open,
        /**
         * Callback function to update open state to a new ref object.
         */
        setOpen: (ref) => {
            ref === open ? setOpen(null) : setOpen(ref);
        },
        /**
         * Callback function to close the menu.
         */
        closeMenu: () => setMenuOpen(false),
    };
    useEffect(() => {
        const handleEscape = (event) => {
            switch (event.key) {
                case 'Escape':
                    setOpen(null);
                    break;
                default:
            }
        };
        document.addEventListener('keydown', handleEscape, {
            capture: true,
        });
        return () => document.removeEventListener('keydown', handleEscape, {
            capture: true,
        });
    }, []);
    const classNameList = classNames('c-site-nav', {
        'is-active': menuOpen,
    }, className).trim();
    const panelClassNameList = classNames('c-site-nav__panel', {
        'is-collapsed': !menuOpen,
    }).trim();
    return (React.createElement("div", { className: classNameList, "data-testid": testId, ...otherProps },
        React.createElement("button", { className: "c-site-nav__toggle", type: "button", title: title, onClick: () => setMenuOpen(!menuOpen), "aria-expanded": menuOpen },
            React.createElement(Icon, { className: "c-site-nav__toggle-icon", viaCss: true }),
            React.createElement("span", { className: "c-site-nav__toggle-open-label" }, openMenuText || 'Menu'),
            React.createElement("span", { className: "c-site-nav__toggle-close-label" }, closeMenuText || 'Close Menu')),
        React.createElement("div", { className: panelClassNameList },
            React.createElement("nav", { className: "c-site-nav__navs", "aria-label": "Main Navigation" },
                React.createElement(SiteNavContext.Provider, { value: contextValue }, children)))));
};
SiteNav.displayName = 'SiteNav';
/**
 * Bronson SiteNavArea component (nested).
 * @constructor
 */
function SiteNavArea({ children, ...otherProps }) {
    return (React.createElement("ul", { className: "c-site-nav__nav", ...otherProps }, children));
}
SiteNavArea.displayName = 'SiteNav.Area';
SiteNav.Area = SiteNavArea;
/**
 * Bronson SiteNavItem component (nested).
 * @constructor
 */
const SiteNavItem = ({ active, children, className, dropdownTitle, icon, label, language, navClassName, onClick, reversed, topAtBreakpoint, testId, ...otherProps }) => {
    const areaRef = useRef(null);
    const value = useContext(SiteNavContext);
    /**
     * Create a unique ID for dropdown button
     * and dropdown panel.
     */
    const id = uuid();
    /**
     * Handle clicks outside the {@link SiteNavArea}.
     */
    useEffect(() => {
        const outsideClickHandler = (event) => {
            const eventTarget = event?.target;
            /**
             * Only close the current dropdown when a SiteNavItem is expanded,
             * the event target is not a childElement of the {@link areaRef} and
             * it is not a child of another {@link areaRef} (e.g. multiple navigations)
             * which would also count as an outside click.
             */
            if (value?.open &&
                !areaRef?.current?.parentElement?.contains(eventTarget) &&
                !eventTarget?.closest('.c-site-nav__nav')) {
                value?.setOpen?.(null);
            }
        };
        document.addEventListener('mousedown', outsideClickHandler, {
            capture: true,
        });
        /**
         * Cleanup the hook.
         */
        return () => document.removeEventListener('mousedown', outsideClickHandler, {
            capture: true,
        });
    }, [value]);
    const navClassNameList = classNames('c-site-nav__item', {
        'c-site-nav__item--top-at-breakpoint': topAtBreakpoint,
        'c-site-nav__item--language': language,
    }, navClassName).trim();
    const classNameList = classNames('c-site-nav__link', {
        'is-active': active,
        'is-reversed': reversed,
    }, className).trim();
    /**
     * If there is a {@link DropdownItem},
     * then a `<button>` is rendered, otherwise a `<a>`.
     */
    const TriggerElement = children ? 'button' : 'a';
    function renderIfIcon() {
        if (icon) {
            return React.createElement(Icon, { className: "c-site-nav__link-icon", viaCss: true });
        }
        return null;
    }
    function renderIfDropdownTitle() {
        if (dropdownTitle) {
            return React.createElement("li", { className: "c-site-nav-dropdown__title" }, dropdownTitle);
        }
        return null;
    }
    function renderIfDropdownItems(open) {
        const dropdownClassNameList = classNames('c-site-nav-dropdown', {
            'is-collapsed': open !== areaRef,
        }).trim();
        if (children) {
            return (React.createElement("nav", { className: dropdownClassNameList, id: `${id}-dropdown-panel`, "aria-labelledby": `${id}-dropdown-button` },
                React.createElement("ul", { className: "c-site-nav-dropdown__list" },
                    renderIfDropdownTitle(),
                    children)));
        }
        return null;
    }
    return (React.createElement("li", { className: navClassNameList, ref: areaRef, ...otherProps },
        React.createElement(TriggerElement, { className: classNameList, id: children ? `${id}-dropdown-button` : undefined, type: children ? 'button' : undefined, "aria-current": active ? 'page' : undefined, "aria-controls": children ? `${id}-dropdown-panel` : undefined, "aria-expanded": children ? value.open === areaRef : undefined, "data-testid": testId, tabIndex: children ? undefined : 0, role: children ? undefined : 'link', 
            /**
             * Map keyboard events to handle the state of the current SiteNavItem.
             */
            onKeyDown: (event) => {
                switch (event.key) {
                    case ' ':
                    case 'Enter':
                        value.setOpen?.(areaRef);
                        break;
                    case 'Escape':
                        value.setOpen?.(null);
                        break;
                    default:
                }
            }, 
            /**
             * Note: `onMouseDown` is applied before `onBlur`, other than `onClick`.
             * If the action is performed in `onClick`, `onBlur` could already be
             * performed, closing another menu. In case of e.g. VW6, closing
             * another menu could move content, so the actual click doesn’t
             * happen anymore, which is an undesired behavior. Test this with
             * VW6 menu and 2 items with subitems, open item 1 and click on
             * item 2 (which will move as item 1 submenu disappears just before).
             */
            onMouseDown: (event) => [
                onClick && [
                    /**
                     * If there is no {@link DropdownItem},
                     * a direct action will be performed.
                     */
                    !children && [value.setOpen?.(null), value.closeMenu?.()],
                    /**
                     * Perform the click action.
                     */
                    onClick(event),
                ],
                /**
                 * If there is a {@link DropdownItem},
                 * then open this item after `onClick`.
                 */
                children && [value.setOpen?.(areaRef)],
            ] },
            React.createElement("span", { className: "c-site-nav__link-label" }, label),
            renderIfIcon()),
        renderIfDropdownItems(value.open)));
};
SiteNavItem.displayName = 'SiteNav.Item';
SiteNav.Item = SiteNavItem;
/**
 * Bronson DropdownItem component (nested).
 * @constructor
 */
function DropdownItem({ active, children, onClick, testId, ...otherProps }) {
    const value = useContext(SiteNavContext);
    const classNameList = classNames('c-site-nav-dropdown__link', {
        'is-active': active,
    }).trim();
    return (React.createElement("li", { className: "c-site-nav-dropdown__item", ...otherProps },
        React.createElement("a", { className: classNameList, "aria-current": active ? 'page' : undefined, "data-testid": testId, tabIndex: 0, role: "link" // Improving accessibility through semantic mapping.
            , 
            /**
             * Map keyboard events to close the open {@link SiteNavItem}.
             */
            onKeyDown: (event) => {
                switch (event.key) {
                    case 'Escape':
                        value.setOpen?.(null);
                        break;
                    default:
                }
            }, onClick: (event) => onClick && [
                /**
                 * Close the open {@link SiteNavItem} that
                 * contains this DropdownItem.
                 */
                value.setOpen?.(null),
                /**
                 * Close the whole menu (if the menu is open,
                 * e.g. VW6 or on small viewports).
                 */
                value.closeMenu?.(),
                /**
                 * Perform the click action.
                 */
                onClick(event),
            ] }, children)));
}
DropdownItem.displayName = 'SiteNavItem.DropdownItem';
SiteNavItem.DropdownItem = DropdownItem;
export default SiteNav;
