import { ComponentType, FC, ReactNode } from 'react';

import { createAuthorizationComponent } from '../component/create-authorization-component';
import { AuthorizationDataHookResult, createAuthorizationDataHook } from '../hooks/create-authorization-data-hook';
import { AuthorizationFlowHookResult, createAuthorizationFlowHook } from '../hooks/create-authorization-flow-hook';
import { createAuthorizationContext } from '../context/create-authorization-context';
import { createAuthorizationProvider } from '../context/create-authorization-provider';
import { AuthorizationResult } from '../context';

export type AuthorizationFactoryOptions<T> = {
    authorizationDataProvider: () => Promise<AuthorizationResult<T>>;
    onLoading: ReactNode;
    onError: ReactNode;
    onMissingAuthorization: ReactNode | ((authorizationResult: T) => ReactNode);
    displayName?: string;
};

export type AuthorizationFactoryResult<T> = {
    useAuthorizationData: () => AuthorizationDataHookResult<T>;
    useAuthorizationFlow: () => AuthorizationFlowHookResult<T>;
    Authorization: ComponentType;
    AuthorizationProvider: FC;
};
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export function createAuthorization<T = {}>({
    authorizationDataProvider,
    onLoading,
    onError,
    onMissingAuthorization,
    displayName = 'Authorization',
}: AuthorizationFactoryOptions<T>): AuthorizationFactoryResult<T> {
    const AuthorizationContext = createAuthorizationContext<T>(`${displayName}Context`);
    const AuthorizationProvider = createAuthorizationProvider(`${displayName}Provider`, AuthorizationContext);
    const useAuthorizationFlow = createAuthorizationFlowHook(AuthorizationContext, authorizationDataProvider);
    const useAuthorizationData = createAuthorizationDataHook(useAuthorizationFlow);
    const Authorization = createAuthorizationComponent({
        useAuthorizationFlow,
        onLoading,
        onError,
        onMissingAuthorization,
        displayName,
    });

    return {
        useAuthorizationData,
        useAuthorizationFlow,
        Authorization,
        AuthorizationProvider,
    };
}
