import { FormField, Textarea } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React, { MouseEventHandler } from 'react';

import { InfoIcon } from '../../info-icon';
import { ValidationErrorMessage } from '../errors';

export type ValidatedTextareaProps = {
    name: string;
    maxLength?: number;
    id?: string;
    placeholder?: string;
    testId?: string;
    tooltip?: string;
    onLinkClick?: MouseEventHandler;
    label: string;
    isMandatory?: boolean;
    enableMaxLength?: boolean;
    onFocus?: () => void;
    disabled?: boolean;
    hint?: string;
};

export const ValidatedTextarea: React.FC<ValidatedTextareaProps & React.HTMLAttributes<HTMLTextAreaElement>> = ({
    id,
    testId,
    name,
    tooltip,
    onLinkClick,
    label,
    maxLength,
    placeholder,
    isMandatory = false,
    enableMaxLength = false,
    disabled,
    hint,
}) => {
    const [field, meta] = useField({ name, type: 'textarea' });
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    const errorMessage = <ValidationErrorMessage meta={meta} testId={testId} />;

    return (
        <FormField
            type="textarea"
            onClick={onLinkClick}
            labelText={label}
            testId={`${testId}FormField`}
            id={id}
            errorMessage={errorMessage}
            infoIcon={infoIcon}
            notion={isMandatory}
            hint={hint}
        >
            <Textarea
                id={id}
                testId={testId}
                placeholder={placeholder}
                charCounter
                maxCounter={maxLength}
                enableMaxLength={enableMaxLength}
                disabled={disabled}
                {...field}
            />
        </FormField>
    );
};
