import { FormField, Input, Paragraph } from '@vwfs-bronson/bronson-react';
import { useField } from 'formik';
import React, { FormEventHandler, MouseEventHandler } from 'react';
import { InfoIcon } from '../../info-icon';
import { hasError, hasSuccess, ValidationErrorMessage } from '../errors';

export type ValidatedInputProps = {
    name: string;
    addonText?: string | React.ReactNode;
    reversed?: boolean;
    maxLength?: number;
    label: string;
    hint?: string;
    onLinkClick?: MouseEventHandler;
    type?: 'tel' | 'email' | 'password' | 'search' | 'text' | 'url'; // some theoretically possible HTML types have been remove from list, as they change the layout to a non brand compliant layout
    inputMode?: 'numeric' | 'text' | 'search' | 'tel' | 'url' | 'email' | 'decimal' | 'none';
    testId?: string;
    tooltip?: string | React.ReactNode;
    id?: string;
    placeholder?: string;
    handleChange?: FormEventHandler<HTMLElement>;
    stateIcon?: boolean;
    isMandatory?: boolean;
    withHtmlValidationWarning?: boolean;
    onFocus?: () => void;
    disabled?: boolean;
    addonType?: string;
    addonIcon?: string;
    addonClassName?: string;
    modifierStates?: string;
    inputId?: string;
    className?: string;
    inputClassName?: string;
    ghost?: boolean;
    noBackground?: boolean;
    helpText?: string | React.ReactNode;
    helpTextClassName?: string;
    addonElement?: string;
    autocomplete?: string;
};

export const ValidatedInput: React.FC<ValidatedInputProps & React.HTMLAttributes<HTMLInputElement>> = ({
    children,
    name,
    label,
    hint,
    onLinkClick,
    testId,
    id,
    tooltip,
    handleChange,
    isMandatory = false,
    withHtmlValidationWarning = false,
    inputId,
    inputClassName,
    className,
    helpText,
    helpTextClassName,
    addonElement,
    autocomplete,
    disabled,
    ...inputProps
}) => {
    const [field, meta] = useField({ name, type: 'input' });
    const infoIcon = tooltip ? <InfoIcon text={tooltip} /> : null;

    const errorMessage = (
        <>
            {!disabled && (
                <ValidationErrorMessage
                    meta={meta}
                    testId={testId}
                    withHtmlValidationWarning={withHtmlValidationWarning}
                />
            )}
            {!!helpText && !hasError(meta) && (
                <Paragraph
                    className={`u-text-muted u-text-fs-1 u-pt-xsmall ${helpTextClassName ?? ''}`}
                    testId={`${testId}HelpText`}
                >
                    {helpText}
                </Paragraph>
            )}
        </>
    );

    return (
        <FormField
            type="input"
            onClick={onLinkClick}
            hint={hint}
            labelText={label}
            testId={`${testId}FormField`}
            id={id}
            errorMessage={errorMessage}
            infoIcon={infoIcon}
            onChange={handleChange}
            notion={isMandatory}
            className={className}
        >
            <Input
                error={!disabled && hasError(meta)}
                success={!disabled && hasSuccess(meta)}
                testId={testId}
                id={inputId}
                className={inputClassName}
                addonElement={addonElement}
                autoComplete={autocomplete}
                disabled={disabled}
                {...inputProps}
                {...field}
            >
                {children}
            </Input>
        </FormField>
    );
};
