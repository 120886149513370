import React from 'react';
import classNames from 'classnames';
import { Heading } from '../Heading/Heading';
import { Button } from '../Button/Button';
import { Icon, IconName } from '../Icon/Icon';
/**
 * Bronson ItemTeaser component.
 * @see https://bronson.vwfs.tools/default/components/detail/bronson-item-teaser.html
 * @constructor
 */
export function ItemTeaser({ alternative, block, blockSmallScreen, children, className, headingLevel = 5, icon, iconClassName, iconDescription, inline, inlineSmallScreen, linkButton, linkIcon = IconName.FORWARD, linkLabel, linkProps, row, small, title, testId, ...otherProps }) {
    const articleClassNameList = classNames('c-item-teaser', {
        // Convenience props from Bronson variants.
        'c-item-teaser--small': small,
        'c-item-teaser--inline': inline,
        'c-item-teaser--alternative': alternative,
        'c-item-teaser--row': row,
        'c-item-teaser--block': block,
        'c-item-teaser--inline@small-screen': inlineSmallScreen,
        'c-item-teaser--block@small-screen': blockSmallScreen,
    }, className).trim();
    const iClassNameList = classNames('c-item-teaser__icon', iconClassName).trim();
    function renderIfItemTeaserLinkLabel() {
        if (linkLabel) {
            const linkAttrs = linkButton ? {} : { link: true, simple: true, small: true };
            return (React.createElement("div", { className: "c-item-teaser__link" },
                React.createElement(Button, { icon: linkIcon === null ? undefined : linkIcon, ...linkAttrs, ...linkProps }, linkLabel)));
        }
        return null;
    }
    function renderIfIcon() {
        if (icon) {
            return (React.createElement(React.Fragment, null,
                React.createElement(Icon, { className: iClassNameList, name: icon }),
                React.createElement("span", { className: "c-item-teaser__icon-description u-visually-hidden" }, iconDescription)));
        }
        return null;
    }
    function renderIfTitle() {
        if (title) {
            return (React.createElement(Heading, { level: headingLevel, className: "c-item-teaser__title" }, title));
        }
        return null;
    }
    function renderIfText() {
        if (children) {
            return React.createElement("div", { className: "c-item-teaser__text" }, children);
        }
        return null;
    }
    return (React.createElement("article", { ...otherProps, "data-testid": testId, className: articleClassNameList },
        renderIfIcon(),
        React.createElement("div", { className: "c-item-teaser__body" },
            renderIfTitle(),
            renderIfText(),
            renderIfItemTeaserLinkLabel())));
}
