import { Footer, Footnote } from '@vwfs-bronson/bronson-react';
import React from 'react';
import { LegalFooterCMS, LegalFooterLinkCMS } from '@cp-shared-9/apis';
import { Override } from '@cp-shared-9/common-utilities';

export type LegalFooterProps = Override<
    LegalFooterCMS,
    {
        legalText?: string | React.ReactNode;
    }
>;

/**
 * A legal footer consolidating all necessary legal footprint
 */
export const LegalFooter: React.FC<LegalFooterProps> = ({ links, legalText, copyrightText, footnotes }) => {
    return (
        <Footer>
            <Footer.ModuleMetaNav copyrightText={copyrightText}>
                {links.map(({ text, url = '', externalUrl }: LegalFooterLinkCMS) =>
                    !!externalUrl ? (
                        <Footer.ModuleMetaNav.Item
                            normalLink={true}
                            href={externalUrl}
                            key={text}
                            linkProps={{ target: '_blank' }}
                        >
                            {text}
                        </Footer.ModuleMetaNav.Item>
                    ) : (
                        <Footer.ModuleMetaNav.Item key={text} href={url}>
                            {text}
                        </Footer.ModuleMetaNav.Item>
                    ),
                )}
            </Footer.ModuleMetaNav>

            {legalText || footnotes ? (
                <Footer.ModuleFootnotes footerText={legalText}>
                    {footnotes ? (
                        <Footnote numbered>
                            {footnotes.map(footnote => (
                                <Footnote.Item key={footnote}>{footnote}</Footnote.Item>
                            ))}
                        </Footnote>
                    ) : null}
                </Footer.ModuleFootnotes>
            ) : null}
        </Footer>
    );
};
