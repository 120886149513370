import React from 'react';
import classNames from 'classnames';
/**
 * Make all semantic icons available via enum.
 * @see @vwfs-bronson/bronson-default/src/scss/01-settings/_settings.icon-mapping.scss
 */
export var IconName;
(function (IconName) {
    IconName["ACCESSOIRES"] = "semantic-accessoires";
    IconName["ADD"] = "semantic-add";
    IconName["AIR_FILTER"] = "semantic-air-filter";
    IconName["ARROW_RIGHT"] = "semantic-arrow-right";
    IconName["BACK"] = "semantic-back";
    IconName["BENEFIT_FINANCE"] = "semantic-benefit-finance";
    IconName["BRAKE_FLUID"] = "semantic-brake-fluid";
    IconName["BRIEFCASE"] = "semantic-briefcase";
    IconName["BROCHURE"] = "semantic-brochure";
    IconName["CALCULATOR"] = "semantic-calculator";
    IconName["CALENDAR"] = "semantic-calendar";
    IconName["CAR_BODY_TYPE_CABRIO"] = "semantic-car-body-type-cabrio";
    IconName["CAR_BODY_TYPE_COMBI"] = "semantic-car-body-type-combi";
    IconName["CAR_BODY_TYPE_COUPE"] = "semantic-car-body-type-coupe";
    IconName["CAR_BODY_TYPE_CUV"] = "semantic-car-body-type-cuv";
    IconName["CAR_BODY_TYPE_HATCHBACK"] = "semantic-car-body-type-hatchback";
    IconName["CAR_BODY_TYPE_LIFTBACK"] = "semantic-car-body-type-liftback";
    IconName["CAR_BODY_TYPE_LIMOUSINE"] = "semantic-car-body-type-limousine";
    IconName["CAR_BODY_TYPE_MPV"] = "semantic-car-body-type-mpv";
    IconName["CAR_BODY_TYPE_SMALL_CAR"] = "semantic-car-body-type-small-car";
    IconName["CAR_BODY_TYPE_SUV"] = "semantic-car-body-type-suv";
    IconName["CAR_DOOR"] = "semantic-car-door";
    IconName["CAR_HANDOVER"] = "semantic-car-handover";
    IconName["CAR_RACK_BIKE"] = "semantic-car-rack-bike";
    IconName["CAR_RACK_ROOFTOP"] = "semantic-car-rack-rooftop";
    IconName["CAR_RETURN"] = "semantic-car-return";
    IconName["CAR_SEAT_BABY"] = "semantic-car-seat-baby";
    IconName["CAR_SEAT_CHILD"] = "semantic-car-seat-child";
    IconName["CAR_SEAT"] = "semantic-car-seat";
    IconName["CAR_SIDE"] = "semantic-car-side";
    IconName["CAR_TOW_BAR"] = "semantic-car-tow-bar";
    IconName["CAR_VALUE"] = "semantic-car-value";
    IconName["CAR"] = "semantic-car";
    IconName["CHAT"] = "semantic-chat";
    IconName["CHECKMARK"] = "semantic-checkmark";
    IconName["CLOCK"] = "semantic-clock";
    IconName["CLOSE"] = "semantic-close";
    IconName["COLLAPSE"] = "semantic-collapse";
    IconName["CONFIGURATION_DETAILS"] = "semantic-configuration-details";
    IconName["CONTACT"] = "semantic-contact";
    IconName["COOKIE_SETTINGS"] = "semantic-cookie-settings";
    IconName["CROSSED_OUT"] = "semantic-crossed-out";
    IconName["DEALER"] = "semantic-dealer";
    IconName["DELETE"] = "semantic-delete";
    IconName["DETAILS"] = "semantic-details";
    IconName["DIESEL"] = "semantic-diesel";
    IconName["DISLIKE"] = "semantic-dislike";
    IconName["DOWNLOAD"] = "semantic-download";
    IconName["EDIT"] = "semantic-edit";
    IconName["ELECTRIC"] = "semantic-electric";
    IconName["EMISSION"] = "semantic-emission";
    IconName["ENGINE_OIL"] = "semantic-engine-oil";
    IconName["ENGINE"] = "semantic-engine";
    IconName["ENTER_FULLSCREEN"] = "semantic-enter-fullscreen";
    IconName["EQUALSIGN"] = "semantic-equalsign";
    IconName["ERROR"] = "semantic-error";
    IconName["EXIT_FULLSCREEN"] = "semantic-exit-fullscreen";
    IconName["EXPAND"] = "semantic-expand";
    IconName["FAQ"] = "semantic-faq";
    IconName["FAVORITE"] = "semantic-favorite";
    IconName["FEEDBACK"] = "semantic-feedback";
    IconName["FILE"] = "semantic-file";
    IconName["FILTER"] = "semantic-filter";
    IconName["FORWARD"] = "semantic-forward";
    IconName["FOUR_WHEEL_DRIVE"] = "semantic-four-wheel-drive";
    IconName["FRONT_WHEEL_DRIVE"] = "semantic-front-wheel-drive";
    IconName["FS_PAYMENT"] = "semantic-fs-payment";
    IconName["FUEL_NATURAL_GAS"] = "semantic-fuel-natural-gas";
    IconName["FUEL"] = "semantic-fuel";
    IconName["GEAR_OIL"] = "semantic-gear-oil";
    IconName["SHARE_GENERIC"] = "semantic-share-generic";
    IconName["GRID"] = "semantic-grid";
    IconName["GUARANTEE"] = "semantic-guarantee";
    IconName["HIDE"] = "semantic-hide";
    IconName["HYBRID"] = "semantic-hybrid";
    IconName["INFO"] = "semantic-info";
    IconName["LIKE"] = "semantic-like";
    IconName["LIST"] = "semantic-list";
    IconName["LOCATION"] = "semantic-location";
    IconName["LOCATOR"] = "semantic-locator";
    IconName["LOCK"] = "semantic-lock";
    IconName["LOGIN"] = "semantic-login";
    IconName["MAIL"] = "semantic-mail";
    IconName["MENU"] = "semantic-menu";
    IconName["MINUS"] = "semantic-minus";
    IconName["MOBILITY"] = "semantic-mobility";
    IconName["MORE"] = "semantic-more";
    IconName["NAVIGATION"] = "semantic-navigation";
    IconName["NEWS"] = "semantic-news";
    IconName["NOTIFICATION"] = "semantic-notification";
    IconName["OFFERS"] = "semantic-offers";
    IconName["PARAGRAPH"] = "semantic-paragraph";
    IconName["PAUSE"] = "semantic-pause";
    IconName["PERFORMANCE"] = "semantic-performance";
    IconName["PETROL"] = "semantic-petrol";
    IconName["PHONE"] = "semantic-phone";
    IconName["PLANE"] = "semantic-plane";
    IconName["PLAY"] = "semantic-play";
    IconName["PLUS"] = "semantic-plus";
    IconName["PREMIUM_L"] = "semantic-premium-l";
    IconName["PREMIUM_M"] = "semantic-premium-m";
    IconName["PREMIUM_S"] = "semantic-premium-s";
    IconName["PRINT"] = "semantic-print";
    IconName["QUALITY"] = "semantic-quality";
    IconName["RELOAD"] = "semantic-reload";
    IconName["SAFETY_CHECK"] = "semantic-safety-check";
    IconName["SAVE_CLOUD"] = "semantic-save-cloud";
    IconName["SAVE_LOCAL"] = "semantic-save-local";
    IconName["SEARCH"] = "semantic-search";
    IconName["SERVICE"] = "semantic-service";
    IconName["SETTINGS"] = "semantic-settings";
    IconName["SHARE"] = "semantic-share";
    IconName["SHOW"] = "semantic-show";
    IconName["SIGNATURE_DIGITAL"] = "semantic-signature-digital";
    IconName["SMARTPHONE"] = "semantic-smartphone";
    IconName["SNOW_CHAINS"] = "semantic-snow-chains";
    IconName["SOCIAL_FACEBOOK"] = "semantic-social-facebook";
    IconName["SOCIAL_INSTAGRAM"] = "semantic-social-instagram";
    IconName["SOCIAL_LINKEDIN"] = "semantic-social-linkedin";
    IconName["SOCIAL_PINTEREST"] = "semantic-social-pinterest";
    IconName["SOCIAL_RSS"] = "semantic-social-rss";
    /**
     * @DEPRECATED: Remove in v11.
     * @type {IconName.SOCIAL_TWITTER}
     */
    IconName["SOCIAL_TWITTER"] = "semantic-social-twitter";
    IconName["SOCIAL_TIKTOK"] = "semantic-social-tiktok";
    IconName["SOCIAL_VIMEO"] = "semantic-social-vimeo";
    IconName["SOCIAL_WHATSAPP"] = "semantic-social-whatsapp";
    IconName["SOCIAL_X"] = "semantic-social-x";
    IconName["SOCIAL_XING"] = "semantic-social-xing";
    IconName["SOCIAL_YOUTUBE"] = "semantic-social-youtube";
    IconName["SORT_ASC"] = "semantic-sort-asc";
    IconName["SORT_DESC"] = "semantic-sort-desc";
    IconName["SPARK_PLUG"] = "semantic-spark-plug";
    IconName["STAR_FILLED"] = "semantic-star-filled";
    IconName["STAR_HALF"] = "semantic-star-half";
    IconName["STAR_OUTLINE"] = "semantic-star-outline";
    IconName["STEERING_WHEEL"] = "semantic-steering-wheel";
    IconName["STOP"] = "semantic-stop";
    IconName["SUCCESS"] = "semantic-success";
    IconName["SWITCH"] = "semantic-switch";
    IconName["TIRES_WINTER"] = "semantic-tires-winter";
    IconName["TRANSMISSION_AUTOMATIC"] = "semantic-transmission-automatic";
    IconName["TRANSMISSION_MANUAL"] = "semantic-transmission-manual";
    IconName["UP"] = "semantic-up";
    IconName["UPLOAD"] = "semantic-upload";
    IconName["USER"] = "semantic-user";
    IconName["VOLUME_DOWN"] = "semantic-volume-down";
    IconName["VOLUME_UP"] = "semantic-volume-up";
    IconName["VOLUME"] = "semantic-volume";
    IconName["WARNING"] = "semantic-warning";
    IconName["ZOOM_IN"] = "semantic-zoom-in";
    IconName["ZOOM_OUT"] = "semantic-zoom-out";
})(IconName || (IconName = {}));
export var IconSize;
(function (IconSize) {
    IconSize["XXSMALL"] = "xxsmall";
    IconSize["XSMALL"] = "xsmall";
    IconSize["SMALL"] = "small";
    IconSize["MEDIUM"] = "medium";
    IconSize["LARGE"] = "large";
    IconSize["XLARGE"] = "xlarge";
    IconSize["XXLARGE"] = "xxlarge";
})(IconSize || (IconSize = {}));
/**
 * Bronson Icon component.
 * @constructor
 */
export function Icon({ className, name, size, viaCss, ...otherProps }) {
    const classNameList = classNames(className, {
        'c-icon': !viaCss,
        [`c-icon--[${name}]`]: name && !viaCss,
        [`c-icon--${size}`]: size && !viaCss,
    }).trim();
    return React.createElement("i", { className: classNameList, "aria-hidden": "true", role: "img", ...otherProps });
}
